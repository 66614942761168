<template>
  <div>
    <event-table v-if="happening_events" :kind=" $t('ongoing') " :events="happening_events"></event-table>
    <event-table v-if="upcoming_events" :kind="$t('upcoming')" :events="upcoming_events"></event-table>
    <event-table v-if="past_events" :kind="$t('past')" :events="past_events"></event-table>
  </div>
</template>

<script>
import EventTable from '../index/event_table'

export default {
  props: ['upcoming_events', 'happening_events', 'past_events'],
  components: {
    'event-table': EventTable
  },
}
</script>

<style>
  
</style>
