/* eslint no-console: 0 */

import Vue from 'vue'
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
import App from '../../client/competition/events.vue'

Vue.use(VueResource);
Vue.use(VueI18n);
Vue.http.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content;

const i18n = new VueI18n({
  locale: 'current',
  messages: translations
})


let initComponent = function(){
  const node = document.getElementById('competition-events')
  const props = JSON.parse(node.getAttribute('data'))
  const rounds_form = new Vue({
    i18n,
    render: h => h(App, {props}),
  }).$mount(node)
}

document.addEventListener('DOMContentLoaded', () => {
  initComponent();
});
